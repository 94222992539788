import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';

const Page404: FC<Page404Types.Page404Props> = ({
  data: {
    pageNotFound: { seo, urls, text },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />

      <Container fluid>
        <Container gutter={false}>
          <Typography as="h1" align="center">
            {text}
          </Typography>
        </Container>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    pageNotFound(lang: { eq: $lang }) {
      ...Page404Fragment
    }
  }
`;
export default Page404;
